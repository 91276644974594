<template>
  <div class="container" v-show="data">
    <div class="card">
      <div class="title">基本信息</div>
      <div class="field">
        <div class="field-label">订单编号：</div>
        <div class="field-value">{{ data.code }}</div>
      </div>
      <div class="field">
        <div class="field-label">订单状态：</div>
        <div class="field-value">{{ dealStatus(data.status) }}</div>
      </div>
      <div class="field">
        <div class="field-label">用户昵称：</div>
        <div class="field-value">{{ data.nickName }}</div>
      </div>
      <div class="field">
        <div class="field-label">收货人：</div>
        <div class="field-value">{{ data.contactName }}</div>
      </div>
      <div class="field">
        <div class="field-label">手机号码：</div>
        <div class="field-value">{{ data.mobileNumber }}</div>
      </div>
      <div class="field">
        <div class="field-label">收货地址：</div>
        <div class="field-value">{{ data.address }}</div>
      </div>
      <div class="field">
        <div class="field-label">订单金额：</div>
        <div class="field-value">{{ _pennyToYuan(data.goodsAmount) }}元</div>
      </div>
    </div>
    <div class="card">
      <div class="title">商品详情</div>
      <div
        class='good'
        v-for="(item, index) in data.itemList"
        :style="{ borderBottom: index === data.itemList.length - 1 ? 'none' : '1px solid red'  }"
        :key="item.goodsName"
      >
        <div class="field">
          <div class="field-label">商品名称：</div>
          <div class="field-value">{{ item.goodsName }}</div>
        </div>
        <div class="field">
          <div class="field-label">商品主图：</div>
          <div class="field-value good-imgs">
            <img v-for="o in item.imgUrls" :key="o" :src="o" />
          </div>
        </div>
        <div class="field">
          <div class="field-label">商品货号：</div>
          <div class="field-value">{{ item.productCode }}</div>
        </div>
        <div class="field">
          <div class="field-label">商品规格：</div>
          <div class="field-value">{{ item.skuName }}</div>
        </div>
        <div class="field">
          <div class="field-label">商品数量：</div>
          <div class="field-value">{{ item.quantity }}件</div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="title">订单信息</div>
      <div class="field">
        <div class="field-label">提交时间：</div>
        <div class="field-value">{{ data.createdDate }}</div>
      </div>
      <div class="field">
        <div class="field-label">支付时间：</div>
        <div class="field-value">{{ data.paySuccessTime }}</div>
      </div>
      <div class="field">
        <div class="field-label">支付方式：</div>
        <div class="field-value">{{ data.payPlatform === 1 ? "微信" : data.payPlatform === 2 ?  "余额" : "" }}</div>
      </div>
      <div class="field">
        <div class="field-label">发货时间：</div>
        <div class="field-value">{{ data.sendTime }}</div>
      </div>
      <div class="field">
        <div class="field-label">快递单号：</div>
        <div class="field-value">{{ data.deliveryCode }}</div>
      </div>
      <div class="field">
        <div class="field-label">物流信息：</div>
        <div class="field-value">{{ data.deliveryCompany }}</div>
      </div>
      <div class="field">
        <div class="field-label">收货时间：</div>
        <div class="field-value">{{ data.receiveTime || data.finishTime }}</div>
      </div>
      <div class="field">
        <div class="field-label">关闭时间：</div>
        <div class="field-value">{{ data.closeTime }}</div>
      </div>
      <div class="field">
        <div class="field-label">发货人姓名：</div>
        <div class="field-value">{{ data.senderName }}</div>
      </div>
      <div class="field">
        <div class="field-label">发货人电话：</div>
        <div class="field-value">{{ data.senderPhone }}</div>
      </div>
    </div>
    <div class="card">
      <div class="title">备注</div>
      <div class="field">
        <div class="field-label">商家备注：</div>
        <div class="field-value">{{ data.sellerRemark }}</div>
      </div>
      <div class="field">
        <div class="field-label">买家备注：</div>
        <div class="field-value">{{ data.payRemark }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as orderService from '@/api/order'
import * as categoryService from '@/api/category'
import * as plateService from '@/api/plate'

export default {
  data () {
    return {
      data: null,
      categories: [],
      plates: []
    }
  },
  created () {
    this.fetchOrderDetail()
    this.fetchCategories()
    this.fetchPlates()
  },
  methods: {
    async fetchOrderDetail () {
      const id = this.$route.params.id
      const detail = await orderService.detail(id)
      this.data = detail
    },
    async fetchCategories () {
      try {
        const res = await categoryService.list()
        const newData = res.list.map(item => {
          const result = {
            value: item.id,
            label: item.name,
            children: item.children.map(o => ({ label: o.name, value: o.id }))
          }
          return result
        })
        this.categories = newData
      } catch (err) {
        // handle error
      }
    },
    async fetchPlates () {
      try {
        const res = await plateService.list()
        this.plates = res.list
      } catch (err) {
        // handle error
      }
    },
    getSpecName (data) {
      const cur = data[0]
      return `${cur.name} - ${cur.spec.name}`
    },
    dealStatus (val) {
      switch (val) {
        case 0: return '待支付'
        case 1: return '待发货'
        case 2: return '待收货'
        case 3: return '已完成'
        case 4: return '已关闭'
        case 5: return '已关闭'
        default: return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  text-align: left;
}

.card {
  background: #FFFFFF;
  padding: 40px;
  margin: 10px auto;
}

 .good {
   padding-top: 16px;
   padding-bottom: 16px;
   border-bottom: 1px solid red;
   &-imgs {
     display: flex;
      >img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        margin-right: 20px;
      }
   }
 }

.title {
  color: #5B5B5BFF;
  font-size: 20px;
  margin-bottom: 24px;

  &:before {
    content: '';
    background: #1D90FBFF;
    width: 4px;
    height: 18px;
    display: inline-block;
    margin-right: 8px;
  }
}

.field {
  color:#7D7E8E;
  font-size: 16px;
  display: flex;
  margin: 15px auto;
}

</style>
